$base-color: #f8941c;
$dark-color: #58585a;

header {
  display: grid;
  grid-template-columns: 178px 1fr 178px;

  @media(max-width: 1200px) {
    grid-template-columns: 60px 1fr 60px;
  }

  .header-left {
    height: 65px;
    margin-left: 5px;

    @media(max-width: 1200px) {
      margin-top: 15px;
      height: 40px;
    }
    @media(max-width: 1024px) {
      margin-top: 0;
      height: 40px;
    }
  }

  .navbar {
    button {
      font-size: 1.25em;
      background: none;
      margin: 15px 12px 5px 12px;
      padding: 12px 5px;
      border: 0;
      outline: none;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      cursor: pointer;
      position: relative;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
      //border-bottom: 1px solid transparent;

      &:before {
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        position: relative;
        -webkit-font-smoothing: antialiased;
      }

      &:after, {
        background: $base-color;
        content: '';
        position: absolute;
        z-index: -1;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
        width: 100%;
        height: 0;
        top: 50%;
        left: 50%;
        opacity: 0;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
      }

      &:hover:after {
        height: 74%;
        opacity: 1;
      }

      &:active:after {
        height: 120%;
        opacity: 1;
      }

      &.is_active {
        background: $base-color;
        padding: 6px 5px;
      }

    }

    @media(max-width: 1024px) {
      display: grid;
      justify-items: center;

      button {
        margin: 5px 15px;
      }
    }
  }

  .header-right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 17px 5px 0 0;
    justify-self: end;

    .logout-Button, .user-button {
      color: $dark-color;
      border: transparent;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      align-self: start;

      &:hover {
        color: $base-color;
      }

    }

    @media(max-width: 1024px) {
      margin: 0 5px 0 0;
    }
  }


}

hr {
  border: 0;
  height: 1px;
  background: black linear-gradient(to right, $base-color, $dark-color, $base-color);
}