$base-color: #f8941c;
$dark-color: #58585a;

html, body, main, #root {
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

div.main {
  display: block;
  text-align: center;
  height: 100%;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

h2 {
  max-width: 600px;
  margin: 20px auto;
}

button.regularButton {
  font-size: 1em;
  background-color: #171717;
  color: white;
  padding: 6px 16px;
  cursor: pointer;
  //border: transparent;
  border: 2px solid black;

  &:hover {
    color: black;
    background-color: $base-color;
    border: 2px solid $dark-color;
  }

  &.save {
    &:hover {
      background-color: $base-color;
    }
  }

  &.remove {
    &:hover {
      background-color: $base-color;
    }
  }

  &:active {
    border-radius: 0;
  }
}

button.personButton {
  text-align: left;
  font-size: large;
  margin-top: 10px;
  padding: 10px 10px;
  width: 100%;
  background-color: white;

  &:hover {
    background-color: #f2f2f2;
  }
}

.error-alert {
  margin: 5px;
}

a {
  text-decoration: none;
}