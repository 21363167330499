.group-blocks {
  // the grid in which the two columns base
  display: grid;
  grid-template-columns: 45% 45%;
  grid-column-gap: 10%;
  grid-row-gap: 10px;
  text-align: center;
  margin: auto;

  .groupview-column {
    height: 500px;

    // block heading
    .group-block-name {
      margin-bottom: 5px;
      margin-left: 5px;
      margin-top: 0;
      text-align: left;
    }

    // the bordered area
    .flex2 {
      padding: 10px;
      border: 2px solid #d9d9d9;
      overflow-y: scroll;
      height: 90%;
      //display: flex;
      //flex-direction: column;

      .groupSearchPerson {
        font-size: 18px;
        display: grid;
        grid-template-columns: 47% 38%;
        grid-column-gap: 13px;
        grid-row-gap: 5px;
        text-align: left;
        //position: sticky;
        //top: 0;
        //background-color: white;

        .fasearch {
          float: left;
          color: #333333;
        }

        label {
          float: left;
          margin-left: 5px;
        }
      }

      .personDiv {
        height: 30px;
        display: grid;
        grid-template-columns: 60% 30% 10%;
        text-align: left;
        font-size: large;
        margin-top: 10px;
        padding: 5px 10px;
        width: 93%;
        background-color: white;
        border-bottom: 2px solid black;

        .personRemove {
          height: 24px;
          font-size: 22px;
          border: transparent;
          background-color: transparent;
          outline: none;

          &:hover {
            background-color: transparent;
            color: red;
          }
        }

        .personAdd {
          height: 24px;
          font-size: 22px;
          border: transparent;
          background-color: transparent;
          outline: none;

          &:hover {
            background-color: transparent;
            color: limegreen;
          }
        }
      }
    }

  }


  .groupButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    max-height: 35px;
    margin-bottom: 40px;
  }

  @media(max-width: 800px) {
    grid-template-columns: 100%;
    grid-gap: 10px;

    .groupButtons {
      max-width: 600px;
    }
  }
}