$base-color: #ffac40;

#login_page {
  height: 100%;
  text-align: center;
  align-items: center;
  align-content: center;
  margin: 10% auto;

  .logo {
    height: 120px;
    margin: auto;
    text-align: center;
    //border-bottom: 6px solid $base-color;
    margin-bottom: 15px;
  }

  .login-input {
    font-size: 1.25em;
    display: block;
    margin: 20px auto 10px;
    padding: 3px 10px;
    border: 2px black solid;
  }

  p {
    color: dodgerblue;
    cursor: pointer;
    margin-top: 5px;

    &:hover {
      text-decoration-line: underline;
    }
  }

  .login-button {
    font-size: 1.25em;
    margin: 12px 0;
    padding: 5px 20px;
  }
}
