.data-main {
  width: 95%;
  text-align: center;
  margin: auto auto 20px;
}

.instructor-tables {
  text-align: center;
  margin-bottom: 40px;

  .instructor-tables-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;

    @media(max-width: 1015px) {
      grid-template-columns: 100%;
      margin: 0 10px;
      grid-row-gap: 20px;
    }
  }
}

.equipment-tables {
  text-align: center;
  margin-bottom: 40px;

  .equipment-tables-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;

    @media(max-width: 900px) {
      grid-template-columns: 100%;
      margin: 0 10px;
      grid-row-gap: 20px;
    }
  }
}

.date-select {
  font-size: 1em;
  text-align: center;
  margin-top: 20px;

  input {
    margin: 0 10px;
    padding: 5px 0;
  }

  @media(max-width: 540px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 5px;

    label, input {
      justify-self: center;
    }
  }

}
