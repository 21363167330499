.person {
  max-width: 600px;
  text-align: center;
  border: 1px black solid;
  margin: 10px auto;
  padding: 2px;
  font-size: 20px;
  position: relative;
  background-color: #ffffff;
}

.row {
  text-align: left;
  font-size: medium;
  width: 100%;
  border: 0;
  outline: none;
  line-height: 26px;

  .arrow {
    position: absolute;
    font-size: x-large;

    &.right {
      margin-top: 4px;
    }

    &.down {
      margin-top: 0px;
    }
  }

  .hide-arrow {
    display: none;
  }

  .switch {
    float: right;
  }
}

.row:disabled {
  color: black;
}

.person_info {
  padding: 5px 30px;
  background-color: #f2f2f2;
  font-size: 18px;

  input {
    font-size: 16px;
    width: 30px;
    margin-left: 10px;
  }

  .switch {
    margin: 0 20px;
  }


  .disabled {
    input, select {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  form {
    display: grid;
    grid-template-columns: 19% 16% 55%;
    margin-bottom: 5px;
    gap: 5px;
    text-align: left;

    span select {
      width: 60%;
    }

    @media(max-width: 635px) {
      grid-template-columns: 80% 20%;
      gap: 5px;
    }

    @media(max-width: 300px) {
      grid-template-columns: 100%;
      gap: 5px;
      grid-template-rows: 24px;
    }
  }

  select {
    font-size: 16px;
  }

}


.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: lawngreen;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px lawngreen;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}