$base-color: #ffac40;

.create_group {
  display: grid;
  grid-gap: 5%;
  grid-template-columns: 55% 40%;
  width: 100%;
  max-width: 800px;
  margin: 20px auto 30px auto;
  height: 30px;

  input {
    font-size: medium;
    border: 1px solid black;
    padding-left: 10px;
    //height: 90%;
  }

  button {
    padding: 10px;
  }

  @media(max-width: 400px) {
    grid-template-columns: 100%;
    grid-gap: 10px;
    text-align: center;
    margin-bottom: 80px;

    input {
      padding: 10px;
    }

    button {
      width: 50%;
      min-width: 110px;
      align-self: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

}

// style for the individual group row
.group {
  width: 100%;

  .group_row {
    display: block;

    // style for the individual group selection button
    // with option to style group name and number of members individually
    // to make it more readable
    #group_button {
      display: grid;
      grid-template-columns: 300px 30px;

      #group_name {
        color: black;
      }

      #group_members {
        margin: auto 15px;
        color: dimgray;
      }
    }
  }
}

.flex {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 95%;
  height: 40%;
  padding: 10px;
  margin: 0 auto 0 auto;
  border: 2px solid #d9d9d9;
  overflow-y: scroll;

  button {
    text-align: left;
    font-size: large;
    margin-top: 10px;
    padding: 10px 10px;
    width: 100%;
    background-color: white;

    &:hover {
      background-color: #f2f2f2;
    }

    .notSaved {
      display: none;
    }
  }

  button.saved {
    background-color: #d2d2d2;
    position: relative;

    .tick {
      position: absolute;
      right: 10px;
      top: 5px;
      color: #52cd52;
    }

    &:hover {
      background-color: #f2f2f2;

      .tick {
        color: lawngreen;
      }
    }
  }

  @media(max-width:600px) {
    border: none;
  }
}

.previous {
  &:hover {
    color: $base-color;
  }
}

