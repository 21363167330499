$base-color: #f8941c;
$dark-color: #58585a;

.accounts-main {
  max-width: 800px;
  text-align: center;
  margin: auto;

  .addUser {
    max-width: 600px;
    height: 30px;
    margin: 20px auto 30px auto;

    button {
      padding: 10px;
      width: 240px;
    }
  }

  .userTable {
    table {
      text-align: center;
      margin: auto;
      border-collapse: collapse;

      th {
        font-weight: normal;
        font-size: medium;
        background-color: $dark-color;
        color: white;
        padding: 10px 5px;
      }

      td {
        text-align: left;
        padding: 10px;
        border-bottom: 1px solid $dark-color;
      }

      tr {
        &.inactive {
          color: $dark-color;
          background-color: #f2f2f2;
        }
      }

      thead {
        border: 1px $dark-color solid;
      }

      .edit-account {
        padding: 5px;
      }
    }

    @media(max-width: 710px) {
      overflow-x: scroll;
    }
  }
}
