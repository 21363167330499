$base-color: #ffac40;

#profile-edit-page {
  width: 95%;
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
}

.change-password {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;

  input {
    font-size: 16px;
    padding: 3px 10px;
    border: 2px black solid;
    margin: auto;
    max-width: 200px;
  }
}

.change-password-button {
  margin: 20px 0;
}

.show-error {
  display: block;
  margin-bottom: 10px;
}

.hide-error {
  display: none;
}

.strong-label {
  font-weight: bold;
}