.planning-main {
  max-width: 1150px;
  text-align: center;
  margin: auto auto 40px;
}

.newTraining {
  margin-top: 25px;

  .upperGrid {
    display: grid;
    grid-template-columns: repeat(3, 8% 24.5%);
    grid-column-gap: 5px;
    grid-row-gap: 10px;

    select {
      height: 25px;
    }

    input {
      height: 20px;
    }

    @media(max-width: 1060px) {
      grid-template-columns: 70px 1fr;
      margin: 0 10px;
    }
  }

  .lowerGrid {
    display: grid;
    grid-template-columns: 8% 58% 33%;
    grid-column-gap: 5px;
    grid-row-gap: 10px;
    margin-top: 10px;

    @media(max-width: 1060px) {
      grid-template-columns: 70px 1fr;
      margin-left: 10px;
      margin-right: 10px;

      .filler-span {
        display: none;
      }

      .addTraining {
        grid-column: 1 / span 2;
      }
    }
  }

  input, select {
    font-size: medium;
  }

  label {
    font-size: large;
    align-self: center;
    justify-self: end;
    padding-right: 5px;
  }

  button {
    width: 75%;
    justify-self: center;
  }

  .trainingTime {
    display: grid;
    grid-template-columns: 45% 10% 45%;
  }
}

.calendar {
  height: 450px;
  text-align: center;
  margin: 25px auto;
}
