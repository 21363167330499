.user_fields {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-gap: 10px;
  min-width: 200px;
  max-width: 400px;
  text-align: center;
  margin: auto;

  label {
    font-size: large;
    align-self: center;
    justify-self: end;

    &.switch {
      justify-self: start;
    }
  }

  input {
    font-size: medium;
    padding: 3px;
  }
}

.addUserButtons {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 10px;
  max-width: 200px;
  min-width: 80px;
  margin: 24px auto;
}

/* TODO fix page height */
/* The Modal (background) */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 155px auto;
  padding: 20px;
  border: 1px solid #888;
  width: 45%;
  min-width: 400px;
}

.hide {
  display: none;
}