$base-color: #ffac40;

#person_table {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 95%;
  height: 40%;
  padding: 10px;
  margin: 0 auto 0 auto;
  border: 2px solid #d9d9d9;
  overflow-y: scroll;

  .expandable_row {
    width: 100%;

    .expanded {
      background-color: #fcfcfc;
      border-color: #888888;
      padding-bottom: 5px;
      margin-bottom: 10px;
      border-style: solid;
      border-width: 2px;

      .group_name {
        border-style: none;
        margin-bottom: 0;
      }

      @media(max-width: 750px) {
        border-style: none;

        .group_name {
          border-style: solid;
        }
      }
    }

    .group_name {
      font-size: large;
      font-weight: bold;
      padding: 10px 0;
      margin-bottom: 10px;
      width: 100%;
      background-color: #fcfcfc;
      outline: none;

      &:hover {
        background-color: $base-color;
      }
    }

  }

  @media(max-width: 750px) {
    border: none;
    height: auto;
  }
}

#add_person {
  display: grid;
  grid-gap: 5%;
  grid-template-columns: 65% 30%;
  width: 95%;
  max-width: 600px;
  margin: 20px auto 10px auto;
  height: 30px;

  input {
    font-size: 18px;
    height: 90%;
    padding-left: 10px;
  }

  @media(max-width: 460px) {
    grid-template-columns: 100%;
    grid-gap: 10px;
    text-align: center;
    height: auto;

    input {
      height: 28px;
    }

    button {
      min-width: 155px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.saveTraining {
  display: block;
  margin: 20px auto 10px auto;
  min-width: 200px;

  @media(max-width: 460px) {
    min-width: 155px;
  }
}

.back {
  min-width: 200px;
  margin-bottom: 40px;

  @media(max-width: 460px) {
    min-width: 155px;
  }
}