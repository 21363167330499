$base-color: #f8941c;
$dark-color: #58585a;

.data-main {
  width: 95%;
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
}

.bike-table, .fuel-table, .location-table, .session-type-table {
  max-width: 1025px;
  text-align: center;
  margin: auto;
}

.session-tables {
  text-align: center;

  .session-tables-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;

    @media(max-width: 1015px) {
      grid-template-columns: 100%;
      margin: auto 10px;
      grid-row-gap: 20px;
    }
  }
}

.hide-table {
  display: none;
}

.table-selector {
  text-align: center;
  justify-self: center;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(5, 200px);
  grid-column-gap: 5px;

  &.four-buttons {
    max-width: 820px;

    @media(max-width: 900px) {
      max-width: 410px;
      grid-template-columns: repeat(2, 200px);
      grid-row-gap: 5px;
    }

    @media(max-width: 500px) {
      max-width: 205px;
      grid-template-columns: 1fr;
      grid-row-gap: 5px;
    }
  }

  &.five-buttons {
    max-width: 1025px;

    @media(max-width: 1125px) {
      max-width: 615px;
      grid-template-columns: repeat(3, 200px);
      grid-row-gap: 10px;
    }

    @media(max-width: 900px) {
      max-width: 410px;
      grid-template-columns: repeat(2, 200px);
      grid-row-gap: 5px;
    }

    @media(max-width: 500px) {
      max-width: 205px;
      grid-template-columns: 1fr;
      grid-row-gap: 5px;
    }
  }

  button {
    background: none;
    padding: 6px 16px;
    text-align: center;
    text-transform: uppercase;
    border: solid 2px;
    border-color: $base-color;
    color: black;
    display: block;

    height: 50px;
    width: 200px;
    font-size: 18px;
    cursor: pointer;
    outline: none;

    &:hover {
      border-color: $dark-color;
    }

    &.selected-table-button {
      background: $base-color;
      border-color: $dark-color;
    }

    @media(max-width: 500px) {
      padding: 0 16px;
      height: 30px;
      width: 200px;
      font-size: 14px;
    }

  }
}

.show-error {
  display: block;
}

.hide-error {
  display: none;
}


